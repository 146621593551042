import React from "react";
import BonusSidebar from "../Components/BonusSidebar";

export default function News() {
  return (
    <div className="max-w-[1280px] w-full mx-auto px-8 grid grid-cols-1 lg:grid-cols-3">
      <div className="col-span-2"></div>
      <BonusSidebar />
    </div>
  );
}
