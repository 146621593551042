import React from "react";
import { useParams } from "react-router-dom";
import BonusSidebar from "../Components/BonusSidebar";

export default function Bet() {
  const { id } = useParams();

  return (
    <div className="max-w-[1280px] w-full mx-auto px-8 grid grid-cols-1 lg:grid-cols-3">
      <div className="col-span-2">
        <div className="lg:px-2 mt-4 grid gap-[1px]">
          {/* better styled title for each section like oddschecker */}
          <p className="font-bold p-3 bg-gray-900 text-white rounded-tl-lg rounded-tr-lg">
            Match {id} bet
          </p>
        </div>
      </div>
      <BonusSidebar />
    </div>
  );
}
