import React from "react";
import TipCard from "../Components/TipCard";
import NextEventsEvent from "../Components/NextEventsEvent";
import BonusSidebar from "../Components/BonusSidebar";

export default function Home() {
  const matches = require("../data/example.json");

  const tips = [
    { tip: "Marcatore Leao", quota: 12.4 },
    { tip: "1X + Over 1.5", quota: 1.0 },
    { tip: "Multigol 3-5 Squadra Casa", quota: 2.32 },
    { tip: "1X + Over 1.5", quota: 5.0 },
  ];

  return (
    <div className="max-w-[1280px] w-full mx-auto px-8 grid grid-cols-1 lg:grid-cols-3 py-4">
      {/* left main side */}
      <div className="col-span-2 h-fit">
        {/* best bets */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:px-2">
          {/* <p className="font-bold col-span-1 lg:col-span-2">Best Bets</p> */}
          <p className="font-bold p-3 bg-black text-white rounded-lg col-span-1 lg:col-span-2">
            Migliori Proposte
          </p>

          {tips.map((tip) => (
            <TipCard oddsButton={true} tip={tip} />
          ))}
        </div>

        {/* next events */}

        <div className="lg:px-2">
          <div className=" mt-4 grid gap-[1px] rounded-lg overflow-hidden">
            {/* better styled title for each section like oddschecker */}
            <p className="font-bold p-3 bg-black text-white rounded-tl-lg rounded-tr-lg">
              Prossime Partite
            </p>

            {/* header */}
            <div className="grid grid-cols-5 xl:grid-cols-7 bg-eeasytip-red text-white px-4 py-2">
              <p className="col-span-2 xl:col-span-4">Evento</p>
              <p className="text-center">1</p>
              <p className="text-center">X</p>
              <p className="text-center">2</p>
            </div>

            {/* body */}
            {matches.response.map((match) => (
              <NextEventsEvent match={match} />
            ))}
          </div>
        </div>
      </div>

      {/* bonus sidebar */}
      <BonusSidebar />
    </div>
  );
}
