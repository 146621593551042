import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BonusSidebar from "../Components/BonusSidebar";
import TipCard from "../Components/TipCard";
import OddsTableFilterContext from "../Components/OddsTableFilterContext";

// icons
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import OddsTable from "../Components/OddsTable";
import OddsTableDropdown from "../Components/OddsTableDropdown";
import OddsTableFilter from "../Components/OddsTableFilter";

export default function Match() {
  const [tableOptions, setTableOptions] = useState({
    header: ["1", "X", "2"],
    select: { enabled: false, options: [] },
  });

  const [allTips, setAllTips] = useState(false);

  const tips = [
    "X2",
    "Vince Manchester",
    "1X",
    "Multigol 2-3",
    "Risultato esatto: 1-2",
  ];

  return (
    <div className="max-w-[1280px] w-full mx-auto px-8 grid grid-cols-1 lg:grid-cols-3 py-4">
      {/* teams header with images */}
      <div className="col-span-2 h-fit grid gap-2 px-2">
        {/* x vs y headline */}
        <div className="bg-black text-white mx-auto text-center p-8 h-fit w-full rounded-lg">
          {/* date hour */}
          <p className="flex items-center mx-auto w-fit p-1 rounded-md bg-eeasytip-red text-white text-sm mb-5">
            <AccessTimeRoundedIcon sx={{ color: "white" }} fontSize="small" />
            <p className="ml-1">
              {/* {new Date("2023-09-16T14:00:00Z")
                .toLocaleDateString("it-IT", {
                  weekday: "short",
                  day: "2-digit",
                  month: "long",
                  hour: "2-digit",
                  minute: "2-digit",
                })
                .toUpperCase()
                .replace(" ALLE ORE", ",")} */}
              [Data]
            </p>
          </p>

          {/* match */}
          <div className="font-bold text-2xl items-center mx-auto w-fit grid grid-cols-3">
            <div className="flex flex-col items-center">
              <img
                src="https://b.fssta.com/uploads/application/soccer/team-logos/Placeholder.vresize.250.250.medium.0.png"
                alt=""
                className="w-28"
              />
              <p className="mt-1">[Squadra 1]</p>
            </div>

            <p className="mx-5 text-3xl">VS</p>

            <div className="flex flex-col items-center">
              <img
                src="https://b.fssta.com/uploads/application/soccer/team-logos/Placeholder.vresize.250.250.medium.0.png"
                alt=""
                className="w-28"
              />
              <p className="mt-1">[Squadra 2]</p>
            </div>
          </div>

          {/* add tip button */}
          <Link to="/post/tip">
            <button className="bg-eeasytip-red px-2 py-1 rounded-md mt-5 uppercase">
              Aggiungi proposta
            </button>
          </Link>
        </div>

        <p className="font-bold p-3 bg-black text-white rounded-lg">
          {allTips ? "Tutte le Proposte" : "Migliori Proposte"}
        </p>

        {/* tips */}
        <div className="grid md:grid-cols-2 gap-2">
          {tips
            .slice(0, allTips || tips.length <= 4 ? tips.length : 4)
            .map((tip) => (
              <TipCard oddsButton={true} tip={tip} />
            ))}
        </div>

        {tips.length > 4 && (
          <button
            className="bg-eeasytip-red text-white px-2 py-1 rounded-md w-full"
            onClick={() => setAllTips(!allTips)}
          >
            {allTips ? "Mostra meno" : "Vedi tutte le proposte"}
          </button>
        )}

        <p className="mt-2 font-bold p-3 bg-black text-white rounded-tl-lg rounded-tr-lg rounded-lg">
          Comparatore Quote
        </p>

        {/* comparatore */}
        <div className="w-full bg-white rounded-lg p-3 grid gap-2">
          <div className="grid gap-2 grid-cols-3 md:grid-cols-6">
            <OddsTableFilterContext.Provider
              value={[tableOptions, setTableOptions]}
            >
              {/* 1x2 */}
              <OddsTableFilter
                text={"Esito finale 1X2"}
                options={{
                  header: ["1", "X", "2"],
                  select: {
                    enabled: false,
                    options: [],
                  },
                }}
              />

              {/* Doppia chance */}
              <OddsTableFilter
                text={"Doppia Chance"}
                options={{
                  header: ["1X", "X2", "12"],
                  select: {
                    enabled: false,
                    options: [],
                  },
                }}
              />

              {/* under over */}
              <OddsTableFilter
                text={"Under/Over"}
                options={{
                  header: ["Under", "Over"],
                  select: {
                    enabled: true,
                    options: ["0.5", "1.5", "2.5", "3.5", "4.5"],
                  },
                }}
              />

              {/* goal no goal */}
              <OddsTableFilter
                text={"Goal/NoGoal"}
                options={{
                  header: ["Goal", "No Goal"],
                  select: {
                    enabled: false,
                    options: [],
                  },
                }}
              />

              {/* multigol */}
              <OddsTableFilter
                text={"Multigol"}
                options={{
                  header: ["Multigol"],
                  select: {
                    enabled: true,
                    options: [
                      "1-2",
                      "1-3",
                      "1-4",
                      "1-5",
                      "1-6",
                      "2-3",
                      "2-4",
                      "2-5",
                      "2-6",
                      "3-4",
                      "3-5",
                      "3-6",
                      "4-5",
                      "4-6",
                      "5-6",
                    ],
                  },
                }}
              />

              {/* altro */}
              <OddsTableDropdown
                text={"Altri tipi di scommessa"}
                filters={[
                  <OddsTableFilter
                    text={"[tipo di scommessa]"}
                    options={{
                      header: [],
                      select: {
                        enabled: false,
                        options: [],
                      },
                    }}
                  />,
                  <OddsTableFilter
                    text={"[tipo di scommessa]"}
                    options={{
                      header: [],
                      select: {
                        enabled: false,
                        options: [],
                      },
                    }}
                  />,
                ]}
              />
            </OddsTableFilterContext.Provider>
          </div>

          <p className="text-center text-2xl font-bold my-4">Tabella Quote</p>

          <OddsTable options={tableOptions} />
        </div>
      </div>

      {/* bonus sidebar */}
      <BonusSidebar />
    </div>
  );
}
