import React, { useContext, useState } from "react";
import OddsTableFilter from "./OddsTableFilter";
import OddsTableFilterContext from "./OddsTableFilterContext";

// icons
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

export default function OddsTableDropdown(props) {
  const [isOpen, setIsOpen] = useState(false);

  const [tableOptions, setTableOptions] = useContext(OddsTableFilterContext);

  return (
    <div className="relative">
      <button
        className="text-sm text-white bg-eeasytip-red hover:bg-red-500 p-1 rounded-md flex items-center w-full"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p>Altri tipi di scommessa</p>
        <button
          className={`${
            isOpen ? "rotate-180" : "rotate-0"
          } transition-all duration-300`}
        >
          <ArrowDropDownOutlinedIcon />
        </button>
      </button>

      {/* dropdown */}
      <div
        className={`${
          isOpen ? "scale-100" : "scale-0"
        } transition-all duration-300 origin-top-right absolute bg-white border-2 border-eeasytip-red grid gap-1 right-0 text-white mt-1 rounded-md p-2`}
      >
        <OddsTableFilterContext.Provider
          value={[tableOptions, setTableOptions]}
        >
          {props.filters.map((filter) => filter)}
        </OddsTableFilterContext.Provider>
      </div>
    </div>
  );
}
