import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] p-10 text-center w-11/12 sm:w-[500px] bg-black text-white rounded-md">
      <h1 className="uppercase font-bold text-2xl">Pagina non trovata</h1>
      <p className="mt-6">
        EEasyTip non ha potuto trovare la pagina che stai cercando. Prova a
        ricominciare dalla pagina principale.
      </p>
      <Link to={"/"}>
        <button className="mt-6 p-4 bg-[#111] uppercase font-bold text-sm rounded-md">
          Torna alla pagina principale
        </button>
      </Link>
    </div>
  );
}
