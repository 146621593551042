import React, { useEffect, useState } from "react";
import NavbarItem from "./NavbarItem";
import NavbarDropdown from "./NavbarDropdown";
import Logo from "../Images/logo_new.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { open, close } from "../redux/store";
import { auth } from "../config/firebase";

// icons
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const bets = useSelector((state) => state.betslip.value);

  const isBetslipOpen = useSelector((state) => state.betslip.isOpen);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    setIsOpen(false);
  }, [navigate]);

  console.log("auth", auth);

  return (
    <div className="w-full bg-black z-10 h-[60px] sticky top-0">
      <div className="flex flex-col max-w-screen-xl px-8 mx-auto md:items-center md:justify-between md:flex-row h-[100%] justify-center">
        <div className="flex flex-row items-center justify-between md:px-2">
          <Link
            to={"/"}
            className="text-lg font-semibold tracking-widest uppercase focus:outline-none"
          >
            <img src={Logo} className="h-[40px]" />
          </Link>

          {/* <BetslipButton /> */}

          <button
            className="text-white p-1 font-bold border-green-400 border-2 hover:bg-green-400 relative rounded-md fill-white flex items-center md:hidden"
            onClick={() => {
              isBetslipOpen ? dispatch(close()) : dispatch(open());
              setIsOpen(false);
            }}
          >
            <ReceiptLongOutlinedIcon color="white" />

            <p className="ml-1">Schedina</p>
            {bets.length > 0 && (
              <div className="absolute top-[-10px] right-[-10px] bg-red-500 rounded-full w-5 h-5 flex items-center justify-center text-white text-sm">
                {bets.length}
              </div>
            )}
          </button>

          <button
            className="md:hidden focus:outline-none"
            onClick={() => setIsOpen(!isOpen)}
          >
            {!isOpen && (
              <MenuRoundedIcon sx={{ color: "white" }} fontSize="large" />
            )}

            {isOpen && (
              <CloseRoundedIcon sx={{ color: "white" }} fontSize="large" />
            )}

            {/* <img
              className={`h-12 ${
                isOpen ? "rotate-[3600deg]" : "rotate-0"
              } transition-all duration-1000`}
              src="https://pics.clipartpng.com/Casino_Roulette_PNG_Clipart-1028.png"
              alt=""
            /> */}
          </button>
        </div>

        {/* bet button */}
        {/* <div className="text-white">schedina</div> */}

        {/* h-screen */}
        <nav
          className={`flex flex-col flex-grow items-center md:pb-0 md:px-2 px-8 transition-all duration-300 overflow-hidden md:overflow-visible w-full md:flex md:justify-end md:flex-row absolute top-[60px] left-0 bg-black md:relative md:top-auto md:left-auto md:bg-transparent md:w-auto md:h-auto md:p-0 ${
            isOpen ? "h-[calc(100vh-60px)] pt-4 pb-4" : "h-0 pt-0 pb-0"
          }`}
        >
          <NavbarItem text="home" link="/" />
          <NavbarItem text="bonus" link="/bonus" />
          {/* <NavbarDropdown
            text="esempio"
            links={[
              { text: "uno", link: "/uno" },
              { text: "due", link: "/due" },
              { text: "tre", link: "/tre" },
            ]}
          /> */}

          {/* {auth.currentUser?.displayName} */}

          <div className="mx-2">
            {auth.currentUser ? (
              <Link>
                <img
                  className="rounded-full h-10"
                  src={auth.currentUser?.photoURL}
                  alt=""
                />
              </Link>
            ) : (
              <Link to={"/login"}>
                <button className="text-white flex p-1 border-2 border-eeasytip-red rounded-md font-bold hover:bg-eeasytip-red">
                  <PersonOutlineOutlinedIcon sx={{ color: "white" }} />
                  <p className="ml-1">Login</p>
                </button>
              </Link>
            )}
          </div>

          <button
            className="text-white p-1 border-green-400 border-2 font-bold hover:bg-green-400 relative mt-3 md:mt-0 rounded-md hidden md:flex items-center h-fit"
            onClick={() => {
              isBetslipOpen ? dispatch(close()) : dispatch(open());
              setIsOpen(false);
            }}
          >
            <ReceiptLongOutlinedIcon color="white" />
            <p className="ml-1">Schedina</p>
            {bets.length > 0 && (
              <div className="absolute top-[-10px] right-[-10px] bg-red-500 rounded-full w-5 h-5 flex items-center justify-center text-white text-sm">
                {bets.length}
              </div>
            )}
          </button>
        </nav>
      </div>
    </div>
  );
}
