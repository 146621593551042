import React, { useContext } from "react";
import OddsTableFilterContext from "./OddsTableFilterContext";

export default function OddsTableFilter(props) {
  const [tableOptions, setTableOptions] = useContext(OddsTableFilterContext);

  return (
    <button
      className="text-sm text-white bg-eeasytip-red hover:bg-red-500 p-1 rounded-md w-full"
      onClick={() => setTableOptions({ ...props.options })}
    >
      {props.text}
    </button>
  );
}
