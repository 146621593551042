import React from "react";
import BonusCard from "./BonusCard";

export default function BonusSidebar() {
  return (
    <div className="grid gap-2 col-span-1 lg:px-2 h-fit mt-4 lg:mt-0">
      <p className="font-bold p-3 bg-black text-white rounded-tl-lg rounded-tr-lg rounded-lg">
        Bonus
      </p>

      {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map(() => (
        <BonusCard />
      ))}
    </div>
  );
}
