import React from "react";
import Logo from "../Images/logo_new.png";
import FooterBanner from "../Images/footer_banner.jpg";

// icons
import { AiOutlineInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="bg-black text-white w-full p-10">
      <div className="flex flex-col max-w-screen-xl px-8 mx-auto md:justify-between md:flex-row h-[100%]">
        <div className="md:w-1/4 max-w-xs">
          <img src={Logo} alt="" />
          {/* socials */}
          <div className="mt-2">
            <a href="https://www.instagram.com/eeasytip">
              <AiOutlineInstagram size={30} />
            </a>
          </div>
        </div>

        <div className="my-8 md:my-0 flex flex-col">
          <Link to={"/"}>Home</Link>
          <Link to={"/bonus"}>Bonus</Link>
        </div>

        <div className="md:w-1/2 text-xs">
          <p>
            Questo blog non è una testata giornalistica ed è aggiornato senza
            periodicità. Non può considerarsi un prodotto editoriale ai sensi
            della legge n. 62 del 7.03.2001. Le previsioni e i pronostici
            presenti all’interno del blog sono frutto di opinioni personali che
            riguardano il possibile esito di eventi sportivi, scelti a
            discrezione dell’autore, e sono espressi precedentemente allo
            svolgersi degli eventi stessi. L’autore cerca di fornire dati
            accurati e aggiornati ma non offre alcuna garanzia assoluta circa
            l’accuratezza o l’attualità del contenuto del sito, come non si
            assume alcuna responsabilità per eventuali errori o omissioni.
            L’autore dichiara di non essere responsabile per i commenti inseriti
            nei post. Eventuali commenti dei lettori, lesivi dell’immagine o
            dell’onorabilità di persone terze, non sono da attribuirsi
            all’autore, nemmeno se il commento viene espresso in forma anonima o
            criptata. L’autore del blog si riserva il diritto di rimuovere senza
            preavviso e a suo insindacabile giudizio commenti che risultino
            offensivi, volgari, blasfemi, inutili, altamente provocatori o che
            abbiano contenuti di natura pubblicitaria. Inoltre, l’autore non è
            in alcun modo responsabile del contenuto dei commenti inseriti dagli
            utenti del blog: questi ultimi, pertanto, se ne assumono la totale
            responsabilità. Ogni visitatore del blog si impegna a prendere
            visione di questo disclaimer e ad accettarne tutte le condizioni.
          </p>
          <img className="mt-2" alt="" src={FooterBanner} />
        </div>
      </div>
    </div>
  );
}
