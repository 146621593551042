import React, { useEffect, useState } from "react";
import { add } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";

export default function BetButton(props) {
  const dispatch = useDispatch();

  const match = props.match;
  const betType = props.betType;

  const bets = useSelector((state) => state.betslip.value);

  return (
    <div
      className={`mx-auto flex justify-center items-center h-fit flex-col md:flex-row w-fit p-[2px] md:p-1 rounded-md cursor-pointer border-2 border-eeasytip-red ${
        bets.some(
          (existingBet) =>
            existingBet.matchId === match.fixture.id &&
            existingBet.bet === betType
        )
          ? "bg-eeasytip-red text-white"
          : "hover:bg-red-200 bg-white text-black"
      } `}
      onClick={() => {
        dispatch(
          add({
            matchId: match.fixture.id,
            match: {
              home: match.teams.home.name,
              away: match.teams.away.name,
            },
            bet: betType,
          })
        );
      }}
    >
      {props.icon && (
        <img
          className="h-10 rounded-md"
          src="https://mb.cision.com/Public/20877/3493250/981db35c050c696e_800x800ar.jpeg"
          alt=""
        />
      )}
      <p className={`md:${props.icon ? "ml-1" : "mx-1"} text-sm lg:text-base`}>
        {/*3.75*/}[Quota]
      </p>
    </div>
  );
}
