import React from "react";
import BetButton from "./BetButton";

export default function OddsTable(props) {
  // under over select: 0.5 1.5 2.5 3.5 4.5
  const options = { ...props.options };

  return (
    <table className="border-separate border-spacing-2">
      <thead>
        <tr>
          <th></th>
          <th></th>
          {options.header.map((title) => (
            <th>{title}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {[1, 2, 3, 4, 5].map((odd) => (
          <tr className="text-center">
            <td className="w-full">
              <div className="relative">
                <img
                  src="https://www.goomlandscapes.co.nz/wp-content/uploads/2018/08/logo-placeholder.png"
                  alt=""
                  className="h-[90px]"
                />

                <p className="text-[11px] md:text-base absolute bottom-1 left-0 text-gray-400">
                  Bookmaker {odd}
                </p>
              </div>
            </td>
            <td>
              {" "}
              {options.select.enabled && (
                <select className="outline-none rounded-md p-2 cursor-pointer">
                  {options.select.options.map((option) => (
                    <option>{option}</option>
                  ))}
                </select>
              )}{" "}
            </td>

            {options.header.map((element) => (
              <td>
                <BetButton icon={false} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
