// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Authentication
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Firestore
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAM6mgVrhxI9Sp--raGkbqKrE9iBI-Jff0",
  authDomain: "eeasytip-website.firebaseapp.com",
  projectId: "eeasytip-website",
  storageBucket: "eeasytip-website.appspot.com",
  messagingSenderId: "923479223238",
  appId: "1:923479223238:web:3c50e498c6f05beaeda0c7",
  measurementId: "G-49G6X57D35",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Authentication
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();

// Firestore
export const db = getFirestore(app);
