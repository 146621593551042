import React from "react";
import { remove } from "../redux/store";
import { useDispatch } from "react-redux";

export default function BetslipBet(props) {
  const data = {
    ...props.bet,
  };

  const dispatch = useDispatch();

  return (
    <div className="w-full bg-gray-700 text-white mb-3 rounded-lg p-4 flex justify-between items-start">
      <p>
        <div className="flex">
          <p>{data.match.home}</p>
          <p className="mx-2 font-bold">vs</p>
          <p>{data.match.away}</p>
        </div>
        {data.bet === "1"
          ? data.match.home + " vincente"
          : data.bet === "2"
          ? data.match.away + " vincente"
          : "Pareggio"}{" "}
        - Quota 2.55
      </p>

      <button onClick={() => dispatch(remove(data))}>
        <svg viewBox="0 0 20 20" className="w-5 h-5">
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
            className="fill-white"
          ></path>
        </svg>
      </button>
    </div>
  );
}
