import React, { useContext } from "react";
import PostTipModalContext from "./PostTipModalContext";

// icons
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export default function PostTipModal(props) {
  const [openModal, setOpenModal] = useContext(PostTipModalContext);

  return (
    <>
      <div className="absolute left-0 top-0 z-50 h-full w-full bg-black opacity-50"></div>
      <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white border-2 border-eeasytip-red z-50 p-10 rounded-md w-5/6 md:w-1/3">
        <p className="uppercase text-2xl font-bold">Motivazione</p>

        {/* <h1>{props.bet}</h1> */}
        <button
          className="bg-eeasytip-red rounded-sm absolute top-0 right-0"
          onClick={() => {
            setOpenModal(!openModal);
          }}
        >
          <CloseRoundedIcon sx={{ color: "white" }} fontSize="large" />
        </button>

        <div className="relative">
          <textarea
            className="w-full mt-2 bg-gray-100 border border-eeasytip-red rounded-sm resize-none outline-none p-2"
            placeholder="Scrivi qui..."
            cols="15"
            rows="7"
          ></textarea>

          <p className="absolute bottom-2 right-2 text-gray-600 text-xs">
            Facoltativo
          </p>
        </div>

        <button className="mt-2 bg-eeasytip-red text-white py-2 px-3 rounded-sm uppercase text-sm font-bold">
          Pubblica proposta
        </button>
      </div>
    </>
  );
}
