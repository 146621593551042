import React from "react";
import { Link } from "react-router-dom";

// icons
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";

export default function BonusCard(props) {
  const colors = [
    "#14805e",
    "#151b4d",
    "#000000",
    "#f7b234",
    "#d2171f",
    "#343635",
    "#14805e",
    "#151b4d",
    "#000000",
    "#f7b234",
    "#d2171f",
  ];

  return (
    <div
      className={"rounded-lg w-full h-fit p-4"}
      style={{
        backgroundColor: colors[Math.floor(Math.random() * colors.length)],
      }}
    >
      <div className="flex justify-between items-center">
        {/* <img
          className="max-w-[120px]"
          src="https://logos-download.com/wp-content/uploads/2022/01/Betfair_Logo.png"
          alt=""
        /> */}
        <p className="text-white">[Bookmaker]</p>

        <button className="uppercase py-2 px-4 bg-eeasytip-red rounded-md hover:bg-red-600 text-white text-sm flex">
          <ExitToAppRoundedIcon fontSize="small" />
          <p className="ml-1">Vai al sito</p>
        </button>
      </div>

      <hr className="my-4" />

      <p className="font-bold text-white">
        {/* 100% Purchase Match up to $100 Fliff Cash plus 600,000 Fliff Coins */}
        [Descrizione Bonus]
      </p>
    </div>
  );
}
