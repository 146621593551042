import React from "react";

// icons
import SportsSoccerOutlinedIcon from "@mui/icons-material/SportsSoccerOutlined";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import { PiHandCoinsFill } from "react-icons/pi";
import TipCard from "../Components/TipCard";

export default function Profile() {
  return (
    <div className="bg-gradient-to-br from-[#111] to-black h-full text-white">
      <div className="max-w-screen-xl px-8 mx-auto h-[100%] py-10 md:flex">
        <div className="w-full md:w-1/2 md:p-4 md:pr-2">
          <div className="w-32 overflow-hidden mx-auto">
            <img
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
              alt=""
              className="rounded-full"
            />
          </div>

          {/* user info */}
          <div className="mt-4 bg-eeasytip-red text-left px-2 py-1 rounded-sm">
            <p className="text-lg text-center uppercase font-bold">
              [username]
            </p>
            <p className="text-xs">[Iscritto dal]</p>
          </div>

          {/* table */}
          <div className="mt-4 bg-white rounded-sm text-black p-4">
            <p className="font-bold uppercase text-center">Numeri e dati</p>
            <table className="w-full mt-4 text-white">
              <tbody>
                <tr>
                  <td className="bg-red-300 p-2">
                    <div className="flex items-center">
                      <SportsSoccerOutlinedIcon fontSize="large" />
                      <div className="ml-2">
                        <p className="text-xl font-bold">10</p>
                        <p className="text-xs font-bold">Giocate</p>
                      </div>
                    </div>
                  </td>
                  <td className="bg-red-400 p-2 w-1/2">
                    <div className="flex items-center">
                      <StarBorderRoundedIcon fontSize="large" />
                      <div className="ml-2">
                        <p className="text-xl font-bold">Corner</p>
                        <p className="text-xs font-bold">
                          Giocata più frequente
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="bg-red-500 p-2">
                    <div className="flex items-center">
                      <PiHandCoinsFill size="35" />
                      <div className="ml-2">
                        <p className="text-xl font-bold">2.53</p>
                        <p className="text-xs font-bold">Quota media</p>
                      </div>
                    </div>
                  </td>
                  <td className="bg-red-600 p-2 w-1/2">
                    <div className="flex items-center">
                      <EmojiEventsRoundedIcon fontSize="large" />
                      <div className="ml-2">
                        <p className="text-xl font-bold">Premier League</p>
                        <p className="text-xs font-bold">
                          Campionato più giocato
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* user's tips list */}
        <div className="md:w-1/2 grid gap-4 auto-rows-max pt-8 md:py-4 md:px-4 md:pl-2">
          <p className="text-xl px-4 py-2 bg-eeasytip-red rounded-md font-bold h-fit">
            Proposte Pubblicate
          </p>
          <div className="h-[500px] overflow-y-auto">
            <div className="grid gap-4 mr-2">
              {[1, 1, 1, 1, 1, 1, 1, 1, 1].map((tip) => (
                <TipCard oddsButton={false} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
