import React, { useEffect, useState } from "react";
import Logo from "../Images/logo_new.png";
import Arrow from "../Images/arrow.png";
import Check from "../Images/check.png";

// icons
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

// database
import { getDocs, collection, addDoc } from "firebase/firestore";
import { db, auth } from "../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

// forms
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function LandingPage() {
  // const [user] = useAuthState(auth);

  // FORM SETTINGS ====================================
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Il formato dell'indirizzo inserito non é corretto.")
      .required("É obbligatorio inserire l'indirizzo E-mail."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // FORM SETTINGS DONE =============================

  const [email, setEmail] = useState("");

  const [success, setSuccess] = useState(false);

  const onAddEmail = async (data) => {
    console.log(data);

    const result = await addDoc(collection(db, "mailingList"), {
      ...data,
    });

    if (result) {
      setSuccess(true);

      // Set a timeout to hide the message after 5 seconds
      setTimeout(() => {
        setSuccess(false);
      }, 5000); // 5000 milliseconds = 5 seconds
    }
  };

  return (
    <div className="bg-gradient-to-b from-[#090909] to-black z-40 w-screen h-full fixed top-0 left-0 text-white overflow-y-auto">
      <div className="mx-auto w-3/4 md:w-fit">
        <img src={Logo} alt="EEASYTIP logo" className="p-10" />
      </div>

      <div className="w-2/3 mx-auto ">
        <div className="w-full lg:w-2/3 my-10 mt-0">
          <p
            className="text-[50px] leading-[50px] uppercase md:text-[120px] md:leading-[100px]"
            style={{ fontFamily: "League Gothic, sans-serif" }}
          >
            Rendiamo <span className="text-eeasytip-red">easy</span> <br /> il
            tuo gioco
          </p>
        </div>

        <ul
          className="text-2xl font-medium"
          style={{ fontFamily: "Baloo Tamma 2, cursive" }}
        >
          <li className="flex items-center">
            <img src={Check} alt="" className="h-12" />
            <p className="ml-2">Le migliori proposte</p>
          </li>

          <li className="flex items-center">
            <img src={Check} alt="" className="h-12" />
            <p className="ml-2">Le quote più alte</p>
          </li>

          <li className="flex items-center">
            <img src={Check} alt="" className="h-12" />
            <p className="ml-2">I bonus più vantaggiosi</p>
          </li>
        </ul>

        <p
          className="uppercase text-[35px] mt-4 text-center md:text-left"
          style={{ fontFamily: "League Gothic, sans-serif" }}
        >
          Tutto in un <span className="text-eeasytip-red">unico</span> posto
        </p>

        {/* add email form */}
        <div className="mx-auto w-full md:w-1/2 my-10 mb-1 text-center">
          <form onSubmit={handleSubmit(onAddEmail)} className="flex">
            <input
              value={email}
              type="text"
              placeholder="Email"
              className="p-4 placeholder:uppercase rounded-tl-sm rounded-bl-sm outline-none text-black border-2 border-eeasytip-red w-full"
              {...register("email")}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              id="submitEmail"
              type="submit"
              value="Invia"
              className="px-3 bg-eeasytip-red rounded-tr-sm rounded-br-sm hidden"
            />
            <label
              for="submitEmail"
              className="px-3 bg-eeasytip-red rounded-tr-sm rounded-br-sm cursor-pointer flex items-center"
            >
              <ChevronRightRoundedIcon fontSize="large" />
            </label>
          </form>

          <p style={{ color: "red" }}>{errors.email?.message}</p>
        </div>
        {/* add email form */}

        <div className="h-[100px] w-full mt-6 rotate-[-20deg] md:w-1/2 md:h-56 md:mt-4 md:rotate-0">
          <img src={Arrow} alt="" className="h-full mx-auto" />
        </div>

        <div className="w-fit mb-10">
          <p
            className="text-[18px] mt-4 md:text-2xl md:mt-0 font-medium"
            style={{ fontFamily: "Baloo Tamma 2, cursive" }}
          >
            Vuoi saperlo <span className="text-eeasytip-red">prima</span> degli{" "}
            <span className="text-eeasytip-red">altri</span> ? <br /> Lascia{" "}
            <span className="text-eeasytip-red">qui</span> la tua email. <br />{" "}
            É <span className="text-eeasytip-red">gratis</span>.
          </p>
        </div>
      </div>

      {success && (
        <div className={"z-50 absolute top-0 left-0 w-full h-full"}>
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-70">
            {/*  */}
          </div>
          <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] px-10 py-8 bg-white text-black rounded-sm w-3/4 max-w-[500px]">
            <p className="text-lg font-bold uppercase text-eeasytip-red">
              Grazie!
            </p>
            <p className="mt-1">
              Ti sei registrato correttamente alla lista d'attesa.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
