import React, { useState } from "react";
import BetButton from "./BetButton";
import { Link } from "react-router-dom";

// icons
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

export default function MatchOdd(props) {
  const matchData = {
    ...props.match,
  };

  console.log(matchData);

  return (
    <div className="bg-white text-black cursor-pointer">
      <div className="grid grid-cols-5 xl:grid-cols-6 p-4">
        <div className="font-bold col-span-2 xl:col-span-3">
          <p className="mb-1">{/*matchData.teams.home.name*/}[Squadra 1]</p>
          <p>{/*matchData.teams.away.name*/}[Squadra 2]</p>
        </div>

        {/* odd 1 */}
        <BetButton match={matchData} betType="1" icon={true} />

        {/* odd 2 */}
        <BetButton match={matchData} betType="X" icon={true} />

        {/* odd 3 */}
        <BetButton match={matchData} betType="2" icon={true} />
      </div>
      <div className="flex justify-between px-4 pb-2">
        {/* <p className="col-span-9 text-gray-300">Ago 04 &bull; 22:00</p> */}
        <p className="col-span-9 text-gray-500">
          {/*new Date(matchData.fixture.date)
            .toLocaleString("it-IT", {
              month: "short",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })
          .replace(",", " • ")*/}
          [Data]
        </p>
        <Link
          to={`/football/${matchData.league.name
            .toLowerCase()
            .replaceAll(" ", "-")}/${matchData.teams.home.name
            .toLowerCase()
            .replaceAll(" ", "")}-${matchData.teams.away.name
            .toLowerCase()
            .replaceAll(" ", "")}`}
          state={{ data: matchData }}
        >
          <button className="flex items-center justify-center">
            <p>Proposte e Quote</p>
            <div className="rounded-sm ml-1 border border-black">
              <ArrowForwardRoundedIcon sx={{ color: "black" }} />
            </div>
          </button>
        </Link>
      </div>
    </div>
  );
}
