import React from "react";
import { Link } from "react-router-dom";

export default function NavbarItem(props) {
  return (
    <Link
      className="px-3 py-2 text-base font-semibold bg-transparent first-letter:uppercase rounded-md md:mt-0 text-white hover:bg-gray-700 focus:outline-none focus:shadow-outline"
      to={props.link}
    >
      {props.text}
    </Link>
  );
}
