import React, { useState } from "react";
import PostTipModal from "./PostTipModal";
import PostTipModalContext from "./PostTipModalContext";

export default function PostTipButton(props) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div
        className="p-2 cursor-pointer rounded-md border-2 border-eeasytip-red"
        onClick={() => setOpenModal(!openModal)}
      >
        <p>{props.text}</p>
        <p className="font-bold">[quota]</p>
      </div>

      <PostTipModalContext.Provider value={[openModal, setOpenModal]}>
        {openModal && <PostTipModal bet={props.text} />}
      </PostTipModalContext.Provider>
    </>
  );
}
