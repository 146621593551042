import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BetslipBet from "./BetslipBet";
import { clear, close, open } from "../redux/store";

// icons
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export default function Betslip() {
  // const [isOpen, setIsOpen] = useState(true);

  const bets = useSelector((state) => state.betslip.value);

  const isOpen = useSelector((state) => state.betslip.isOpen);

  const dispatch = useDispatch();

  // true when no bets are placed s.t. betslip gets closed
  // const [isNoBets, setIsNoBets] = useState(false);

  const [prevBetsLength, setPrevBetsLength] = useState(0);

  useEffect(() => {
    prevBetsLength === 0 && bets.length === 1 && dispatch(open());
    prevBetsLength === 1 && bets.length === 0 && dispatch(close());
    setPrevBetsLength(bets.length);
  }, [bets]);

  return (
    <div
      className={`fixed bg-black right-0 transition-all duration-300 overflow-hidden ${
        isOpen ? "w-full sm:w-[450px]" : "w-0"
      } h-full top-0 z-10`}
    >
      {/* top options banner */}
      <div className="flex justify-between items-center fixed p-4 bg-black border-white border-b-[1px] z-10 w-[inherit]">
        <p className="text-white font-bold text-xl flex items-center">
          Schedina{" "}
          {bets.length > 0 && (
            <p className="ml-3 text-base font-normal rounded-full bg-eeasytip-red text-center px-1">
              {bets.length > 1
                ? bets.length + " partite"
                : bets.length + " partita"}
            </p>
          )}
        </p>

        <button onClick={() => dispatch(close())}>
          <CloseRoundedIcon sx={{ color: "white" }} fontSize="large" />
        </button>
      </div>

      <div className="h-[60px]"></div>

      {/* odds checker */}

      <div className="overflow-y-auto px-4 h-[calc(100%-130px)]">
        {bets[0] && (
          <div className="bg-gray-200 w-full flex justify-between items-center p-2 rounded-md my-3">
            <img
              className="h-14 rounded-md"
              src="https://pbs.twimg.com/profile_images/1605565110306611200/i5DNqreg_400x400.png"
              alt=""
            />

            <div className="flex justify-evenly w-full font-bold text-sm">
              <div className="inline-block">
                <p>Quota:</p>
                <p className="text-green-700">+6758</p>
              </div>
              <div className="inline-block">
                <p>Scommessa:</p>
                <input
                  type="number"
                  min="1"
                  className="font-normal outline-none border-black border-[1px] rounded-sm p-1 w-[60px]"
                />
              </div>
              <div className="inline-block">
                <p>Vincita:</p>
                <p>€150</p>
              </div>
            </div>

            <button className="text-sm flex items-center justify-center bg-gray-900 text-gray-100 p-2 rounded-md">
              <svg width="1em" height="1em" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M19.778 19.778H4.222V4.222h6.667a1.11 1.11 0 100-2.222H4.222C2.99 2 2 3 2 4.222v15.556C2 21 2.989 22 4.222 22h15.556C21 22 22 21 22 19.778V13.11a1.11 1.11 0 10-2.222 0v6.667zM14.222 3.11c0 .614.498 1.111 1.111 1.111h2.878L8.072 14.362a1.108 1.108 0 001.567 1.566l10.139-10.14v2.879a1.11 1.11 0 002.222 0V4a2 2 0 00-2-2h-4.667a1.11 1.11 0 00-1.11 1.111z"
                ></path>
              </svg>
              <p className="ml-1">Scommetti</p>
            </button>
          </div>
        )}

        {bets.map((bet) => (
          <BetslipBet bet={bet} />
        ))}
      </div>

      {bets[0] && (
        <div className="grid grid-cols-2 gap-1 w-[inherit] border-white border-t-[1px] p-4">
          <button
            className="text-white bg-eeasytip-red rounded-md"
            onClick={() => {
              dispatch(clear());
              dispatch(close());
            }}
          >
            Cancella
          </button>
          <button className="text-sm flex items-center justify-center bg-gray-800 text-gray-100 p-2 rounded-md">
            <svg width="1em" height="1em" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M19.778 19.778H4.222V4.222h6.667a1.11 1.11 0 100-2.222H4.222C2.99 2 2 3 2 4.222v15.556C2 21 2.989 22 4.222 22h15.556C21 22 22 21 22 19.778V13.11a1.11 1.11 0 10-2.222 0v6.667zM14.222 3.11c0 .614.498 1.111 1.111 1.111h2.878L8.072 14.362a1.108 1.108 0 001.567 1.566l10.139-10.14v2.879a1.11 1.11 0 002.222 0V4a2 2 0 00-2-2h-4.667a1.11 1.11 0 00-1.11 1.111z"
              ></path>
            </svg>
            <p className="ml-1">Scommetti</p>
          </button>
        </div>
      )}

      {!bets[0] && (
        <p className="text-white absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          Crea la tua schedina!
        </p>
      )}
    </div>
  );
}
