import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import Bonus from "./Pages/Bonus";
import Article from "./Pages/Article";
import News from "./Pages/News";
import AllMatchOdds from "./Pages/AllMatchOdds";
import Login from "./Pages/Login";
import Bet from "./Pages/Bet";
import CreatePost from "./Pages/CreatePost";
import LandingPage from "./Pages/LandingPage";
import Profile from "./Pages/Profile";
import Register from "./Pages/Register";

// this provider component wraps those components that will need to have
// access to the store, so we can wrap all the components under App
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Footer from "./Components/Footer";
import Betslip from "./Components/Betslip";
import EnterBeta from "./Pages/EnterBeta";

function App() {
  const [adminPasswordTmp, setAdminPasswordTmp] = useState("");

  useEffect(() => {
    const password = localStorage.getItem("password");
    if (password != undefined) {
      setAdminPasswordTmp(password);
    }
  }, []);

  return (
    <Provider store={store}>
      <div className="bg-gray-300 grid grid-rows-[auto,1fr,auto] min-h-screen">
        <Router>
          <Navbar />
          {/* <div className="pt-[60px]"></div> */}
          <Betslip />

          {/* main content goes here */}
          <div className="relative">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/landing" element={<LandingPage />} />
              <Route path="/home" element={<Home />} />
              <Route path="/bonus" element={<Bonus />} />
              <Route path="/news" element={<News />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/post/tip" element={<CreatePost />} />
              <Route path="/blog/article/:id" element={<Article />} />
              <Route
                path="/football/:championship/:match"
                element={<AllMatchOdds />}
              />
              <Route path="/bet/:id" element={<Bet />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="/*" element={<NavigateTo404 />} />

              {/* remove for dev */}
              {adminPasswordTmp != "gamblingaffiliation2023" && (
                <>
                  <Route path="/beta" element={<EnterBeta />} />
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/*" element={<LandingPage />} />
                </>
              )}
              {/* remove for dev */}
            </Routes>
          </div>
          {/* main content goes here */}

          <Footer />
        </Router>
      </div>
    </Provider>
  );
}

const NavigateTo404 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/404");
  }, [navigate]);

  return null;
};

export default App;
