import React, { useState } from "react";

// icons
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

export default function Register() {
  const [seePassword, setSeePassword] = useState(false);

  return (
    <div className="bg-gradient-to-br from-[#111] to-black h-full text-white">
      <div className="max-w-screen-xl px-8 mx-auto h-[100%] justify-center text-center py-10">
        <p
          className="uppercase text-[80px] leading-[80px]"
          style={{ fontFamily: "League Gothic, sans-serif" }}
        >
          Registrati
        </p>

        <form className="flex flex-col max-w-[500px] mx-auto text-black">
          <input
            type="email"
            placeholder="E-MAIL"
            className="px-3 py-2 outline-none mt-8 rounded-md bg-white border-2 border-red-300 focus:border-eeasytip-red placeholder:text-sm w-full"
          />

          <div className="mt-3 flex items-center justify-center">
            <input
              type={`${seePassword ? "text" : "password"}`}
              placeholder="PASSWORD"
              className="px-3 py-2 outline-none rounded-md bg-white border-2 border-red-300 focus:border-eeasytip-red placeholder:text-sm w-full"
            />
          </div>

          <div className="mt-3">
            <input
              type={`${seePassword ? "text" : "password"}`}
              placeholder="RIPETI PASSWORD"
              className="px-3 py-2 outline-none rounded-md bg-white border-2 border-red-300 focus:border-eeasytip-red placeholder:text-sm w-full"
            />

            <div
              className="p-2 cursor-pointer"
              onClick={() => setSeePassword(!seePassword)}
            >
              {!seePassword && (
                <RemoveRedEyeOutlinedIcon sx={{ color: "white" }} />
              )}

              {seePassword && (
                <VisibilityOffOutlinedIcon sx={{ color: "white" }} />
              )}
            </div>
          </div>

          <input
            type="submit"
            value="entra"
            className="p-3 mt-5 bg-eeasytip-red text-white w-full rounded-md uppercase text-sm font-bold cursor-pointer"
          />
        </form>

        <button className="flex items-center justify-center mx-auto px-4 py-2 bg-white rounded-md mt-10 w-full max-w-[500px] border-2 border-eeasytip-red">
          <p className="text-lg font-bold mr-4 text-black uppercase">
            Registrati con google
          </p>
          <img
            className="h-8"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2048px-Google_%22G%22_Logo.svg.png"
            alt="Google logo"
          />
        </button>
      </div>
    </div>
  );
}
