import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function EnterBeta() {
  const navigate = useNavigate();

  const [passwordTxt, setPasswordTxt] = useState("");
  const [displayError, setDisplayError] = useState(false);

  let handleSubmit = (password) => {
    localStorage.setItem("password", password);
    if (password == "gamblingaffiliation2023") {
      navigate("/home");
      window.location.reload();
    } else {
      setDisplayError(true);
    }
  };

  return (
    <div className="bg-gradient-to-b from-[#090909] to-black z-40 w-screen h-full fixed top-0 left-0 text-white overflow-y-auto">
      <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center">
        <p className="text-3xl">
          Inserisci la password per entrare nella versione Beta:
        </p>
        <div className="mt-8">
          <input
            placeholder="Inserisci la password..."
            className="px-2 py-1 outline-none text-black"
            onChange={(e) => setPasswordTxt(e.target.value)}
            value={passwordTxt}
          />
          <button
            className="bg-eeasytip-red py-1 px-2"
            onClick={() => handleSubmit(passwordTxt)}
          >
            Invia
          </button>
        </div>
        {displayError && (
          <p className="text-eeasytip-red">Password non corretta</p>
        )}
      </div>
    </div>
  );
}
