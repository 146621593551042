import React from "react";
import TipSelectBox from "../Components/PostTipSelectBox";

export default function CreatePost() {
  return (
    <div className="bg-gradient-to-br from-[#111] to-black h-full text-white">
      <div className="max-w-screen-xl px-8 mx-auto py-4">
        {/* intestazione */}
        <div
          className="mx-auto text-center mb-4"
          style={{ fontFamily: "League Gothic, sans-serif" }}
        >
          <div className="text-[50px] leading-[50px] md:text-[100px] md:leading-[100px] flex flex-col justify-center md:flex-row">
            <p>[Squadra 1]</p>
            <p className="md:mx-8">vs</p>
            <p>[Squadra 2]</p>
          </div>
          <p className="text-3xl uppercase my-4">La tua proposta:</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* select boxes */}
          <TipSelectBox title="Principali" active={true} />
          <TipSelectBox title="[Tipo di scommesse 2]" />
          <TipSelectBox title="[Tipo di scommesse 3]" />
          <TipSelectBox title="[Tipo di scommesse 4]" />
        </div>
      </div>
    </div>
  );
}
