import React, { useState } from "react";

// icon
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import PostTipButton from "./PostTipButton";

export default function PostTipSelectBox(props) {
  const [isOpen, setIsOpen] = useState(props?.active ? true : false);

  return (
    <div>
      <div
        className="border-2 border-black w-full mx-auto skew-x-[15deg] px-5 py-4 relative bg-white text-center font-bold cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="skew-x-[-15deg] text-black break-words flex justify-between items-center uppercase">
          {props.title}
          <button className="bg-eeasytip-red text-white rounded-sm ml-2">
            <div
              className={`${
                isOpen ? "rotate-180" : "rotate-0"
              } transition-all duration-300`}
            >
              <KeyboardArrowDownRoundedIcon sx={{ color: "white" }} />
            </div>
          </button>
        </div>
      </div>

      {/* bets list */}
      {isOpen && (
        <div className="ml-2 w-full mt-1 bg-white text-black border-2 border-black border-t-0 p-3 rounded-md">
          <div>
            <p className="font-bold">1X2 (Tempi Regolari)</p>

            <div className="grid grid-cols-3 gap-2 text-center mt-2">
              <PostTipButton text="1" />
              <PostTipButton text="X" />
              <PostTipButton text="2" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
