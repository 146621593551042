import React, { useState } from "react";
import { Link } from "react-router-dom";

// icons
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

export default function TipCard(props) {
  const matchData = {
    fixture: {
      id: 1035037,
      referee: null,
      timezone: "UTC",
      date: "2023-08-11T19:00:00+00:00",
      timestamp: 1691780400,
      periods: {
        first: null,
        second: null,
      },
      venue: {
        id: 512,
        name: "Turf Moor",
        city: "Burnley",
      },
      status: {
        long: "Not Started",
        short: "NS",
        elapsed: null,
      },
    },
    league: {
      id: 39,
      name: "Premier League",
      country: "England",
      logo: "https://media-2.api-sports.io/football/leagues/39.png",
      flag: "https://media-2.api-sports.io/flags/gb.svg",
      season: 2023,
      round: "Regular Season - 1",
    },
    teams: {
      home: {
        id: 44,
        name: "Burnley",
        logo: "https://media-1.api-sports.io/football/teams/44.png",
        winner: null,
      },
      away: {
        id: 50,
        name: "Manchester City",
        logo: "https://media-1.api-sports.io/football/teams/50.png",
        winner: null,
      },
    },
    goals: {
      home: null,
      away: null,
    },
    score: {
      halftime: {
        home: null,
        away: null,
      },
      fulltime: {
        home: null,
        away: null,
      },
      extratime: {
        home: null,
        away: null,
      },
      penalty: {
        home: null,
        away: null,
      },
    },
  };

  const tipData = { ...props.tip };

  const [isOpen, setIsOpen] = useState(false);
  const [wager, setWager] = useState(10);
  const [bestOdd, setBestOdd] = useState(2.53);

  return (
    <div className="bg-white text-black rounded-lg overflow-hidden h-fit">
      {/* header */}
      <div className="flex items-center justify-center relative overflow-hidden">
        {/* tip header */}
        <div className="flex items-center justify-center w-full p-1 bg-eeasytip-red">
          <div className="flex items-center justify-center uppercase text-white font-bold">
            <p>[Squadra 1] vs [Squadra 2]</p>
          </div>
        </div>
      </div>

      <div
        className={`p-3 ${
          props.oddsButton ? "pb-8" : ""
        }  flex justify-evenly items-center relative`}
      >
        <div className="flex flex-col items-center w-1/2 max-w-[200px]">
          <img
            src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
            className="w-14 h-14 rounded-full object-cover"
            alt=""
          />
          <p className="text-sm mt-2 font-bold text-center break-all">
            [Utente registrato/tipster]
          </p>
        </div>

        <div className="border-2 border-black w-1/2 skew-x-[15deg] px-5 py-4 relative bg-white mb-3 text-center font-bold">
          <div className="skew-x-[-15deg] break-words">
            {/*tipData.tip*/}[Proposta]
          </div>
          <div className="border-2 border-black bg-eeasytip-red text-white absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[50%]">
            <div className="skew-x-[-15deg] break-words px-2 text-sm">
              {/*tipData.quota.toFixed(2)*/}
              [Quota]
            </div>
          </div>
        </div>

        {props.oddsButton && (
          <button
            className="bg-eeasytip-red text-white absolute bottom-2 right-2 rounded-sm"
            onClick={() => setIsOpen(!isOpen)}
          >
            <div
              className={`${
                isOpen && "rotate-180"
              } transition-all duration-300`}
            >
              <KeyboardArrowDownRoundedIcon sx={{ color: "white" }} />
            </div>
          </button>
        )}
      </div>

      {/* calcolatore + bonus finestra  */}
      <div
        className={`${
          isOpen ? "max-h-[393px]" : "max-h-0"
        } transition-all duration-300 overflow-hidden`}
      >
        <div className="h-[1px] bg-gray-300"></div>
        {/* calcolatore */}
        <div className="flex justify-between p-3">
          <div>
            <p>Importo</p>
            <select
              className="outline-none p-2 rounded-md"
              onChange={(e) => setWager(e.target.value)}
            >
              <option value="2">€ 2</option>
              <option value="5">€ 5</option>
              <option value="10" selected>
                € 10
              </option>
              <option value="20">€ 20</option>
              <option value="30">€ 30</option>
              <option value="40">€ 40</option>
              <option value="50">€ 50</option>
            </select>
          </div>

          <div>
            <p>Vinci</p>
            <p>€ {(wager * bestOdd).toFixed(2)}</p>
          </div>

          <div>
            <p>Migliore quota</p>
            <div className="bg-gray-200 flex rounded-md overflow-hidden items-center">
              <img
                src="https://it.rutgers.edu/software-portal/wp-content/uploads/sites/21/2018/11/cropped-logo-placeholder-generic.200x200.png"
                className="w-16 h-16 items-center"
                alt=""
              />
              <p className="p-2">2.53</p>
            </div>
          </div>
        </div>

        {/* slider bonus */}
        <div className="p-3">
          <div className="flex gap-2 overflow-hidden overflow-x-auto">
            {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map(
              (bookmaker) => (
                <img
                  src="https://it.rutgers.edu/software-portal/wp-content/uploads/sites/21/2018/11/cropped-logo-placeholder-generic.200x200.png"
                  className="w-16 h-16 items-center"
                  alt=""
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
