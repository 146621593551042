import React from "react";
import BonusCard from "../Components/BonusCard";

export default function Bonus() {
  return (
    <div className="max-w-[1280px] m-auto px-4 md:px-6 lg:px-8 py-4">
      <p className="font-bold p-3 bg-black text-white rounded-lg col-span-1 lg:col-span-2">
        Bonus
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2">
        <BonusCard />
        <BonusCard />
        <BonusCard />
        <BonusCard />
      </div>
    </div>
  );
}
