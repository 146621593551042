import { configureStore, createSlice } from "@reduxjs/toolkit";

// mock betslip data
// { id: 'whatever_id', match: "whatever_match", bet: "whatever_bet" }
const initialState = {
  value: [],
  isOpen: false,
};

const betslipSlice = createSlice({
  name: "betslip", //used for identifying this specific slice
  initialState: initialState,
  reducers: {
    // initially, state is equal to the empty string we defined above
    add: (state, action) => {
      // state.value.push(action.payload);

      const newBet = action.payload;

      const betExists = state.value.some(
        (existingBet) =>
          existingBet.matchId === newBet.matchId &&
          existingBet.bet === newBet.bet
      );

      if (!betExists) {
        state.value.push(newBet);
      } else {
        // removes already existing bet
        state.value.push(newBet);
        state.value = state.value.filter(
          (match) =>
            match.matchId !== newBet.matchId || match.bet !== newBet.bet
        );
      }
    },

    remove: (state, action) => {
      const removeBet = action.payload;
      console.log(removeBet);
      state.value = state.value.filter(
        (match) =>
          match.matchId !== removeBet.matchId || match.bet !== removeBet.bet
      );
    },

    clear: (state) => {
      state.value = [];
    },

    open: (state) => {
      state.isOpen = true;
    },

    close: (state) => {
      state.isOpen = false;
    },
  },
});

// actions contains the functions we just created
export const { add, remove, clear, open, close } = betslipSlice.actions;

export const store = configureStore({
  reducer: {
    betslip: betslipSlice.reducer,
  },
});
